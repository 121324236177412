import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Collateral Onboarding`}</h1>
    <p>{`Collateral onboarding applications operate like any other `}<a parentName="p" {...{
        "href": "learn/submit/"
      }}>{`sub-proposal`}</a>{` within the MIP governance framework, though we expect that they will be the most regular kind of application. Moreover, these sub-proposals are a critical part of the long-term sustainability and stability of MakerDAO itself, and so they deserve their own dedicated section.`}</p>
    <p><strong parentName="p">{`This section has two clear aims:`}</strong></p>
    <ol>
      <li parentName="ol">{`Make clear and easy the process anyone must follow to submit an application to include a new kind of collateral in the Maker protocol.`}</li>
      <li parentName="ol">{`Track current applications and their states so that the community can keep a close eye on the risks we are agreeing to take on.`}</li>
    </ol>
    <p>{`The process for collateral onboarding is defined in `}<a parentName="p" {...{
        "href": "/MIP6"
      }}>{`MIP6`}</a>{`, which was itself developed from a `}<a parentName="p" {...{
        "href": "https://forum.makerdao.com/t/collateral-onboarding-process-v1/1412"
      }}>{`community-created collateral onboarding document`}</a>{`. The goal of this MIP is to provide an end-to-end framework for scalable collateral onboarding that interoperates with the Governance Cycle and the existing Domain Teams.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      